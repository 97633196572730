<template>
    <div>
        <div class="row">
            <h3 class="col">Products</h3>
            <div class="col fl-x-tr">
                <validated-input class="mr-1"></validated-input>
                <btn color="primary">ADD</btn>
            </div>
        </div>
        <div class="row">
            <product-card v-for="index in 10" :key="index"></product-card>
        </div>
    </div>
</template>

<script>
import ProductCard from '@/views/catalog/catalogComponents/productCard';

export default {
    name       : 'productListing',
    components : { ProductCard }
};
</script>

<style scoped>

</style>
