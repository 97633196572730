<template>
    <div class="col-md-3 wrapper">
        <div class="front-card">
            <img
                src="https://github.com/iamalperen/playground/blob/main/FlippingProductCards/macbook.jpeg?raw=true"/>
            <h6> Enabled </h6>
            <h3> Product Name </h3>
            <p>130 </p>
        </div>
    </div>
</template>

<script>
export default {
    name : 'productCard'
};
</script>

<style scoped>

.wrapper {
  display: flex;
  height: 310px;
}

.front-card {
    box-sizing: border-box;
    position: absolute;
    width: 250px;
    height: 300px;
    background-color: white;
    box-shadow: 0 4px 24px 0 rgb(0 0 0 / 20%);
    border-radius: 20px;
    padding: 10px;
}

.front-card img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

</style>
